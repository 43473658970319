@import './mixins';
#navbar {
  padding: .5rem 1rem;
  position: fixed;
  z-index: 10;
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.sticky {
    background: white;
    box-shadow: 0px 2px 10px rgba(0, 175, 239, 0.3);
    .logo {
      background-image: url('../images/sdlogo.png');
    }
    .nav-item {
      color: #00afef !important;
    }
  }
  .logo {
    height: 50px;
    width: 200px;
    background-image: url('../images/sdlogo.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .menu {
    display: none;
    @include my_bp(sm) {
      display: block;
    }
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      li {
        padding: .5rem 1rem;
        margin: 0 2px;
        border-radius: 1rem;
        transition: all 200ms ease;
        &:hover {
          box-shadow: 2px 2px 5px rgba(200, 200, 200, .5);
          background-color: #00afef;
          a.nav-item {
            color: #ffffff;
          }
        }
        a.nav-item {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-size: 1rem;
          color: #00afef;
          transition: all 200ms ease;
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }
  .menu-btn {
    border: 1px solid #3fafef66;
    border-radius: .5rem;
    padding: .5rem;
    cursor: pointer;
    display: block;
    @include my_bp(sm) {
      display: none;
    }
    &:hover {
      background: #3fafef66;
      svg {
        color: white;
      }
    }
    svg {
      height: 1.5rem;
      width: 1.5rem;
      color: #3fafef66;
    }
  }
}

#sidenav {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 20;
  transform: translateX(-100%);
  transition: all 500ms ease;
  &.open {
    transform: translateX(0);
    .overlay {
      transform: scale(1);
    }
    .menu {
      transform: translateX(0);
    }
  }
  .overlay {
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(4px);
    transform: scale(0);
    transition: all 500ms ease;
  }
  .menu {
    width: 70vw;
    height: 100%;
    position: fixed;
    top: 0;
    background: #3fafef;
    padding-top: 2rem;
    transform: translateX(-100%);
    transition: all 500ms ease;
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      li {
        padding: .75rem 1rem;
        margin: .5rem 1rem;
        border-radius: 1rem;
        transition: all 200ms ease;
        &:hover {
          box-shadow: 2px 2px 5px rgba(200, 200, 200, .5);
          background: white;
          a.nav-item {
            color: #045fe4;
          }
        }
        a.nav-item {
          display: flex;
          justify-content: start;
          align-items: center;
          text-decoration: none;
          font-size: 1rem;
          color: white;
          svg {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }
}